import { Route, RouteConfig } from 'vue-router'
import { SubscriberAreaModule } from '@/store/modules/subscriberArea'
import VsLayouts from '@/layouts/Index.vue'
import { get } from 'lodash'
import { LayoutsEnum } from '@/layouts/types'
import { getMe } from '@/api/consoleApi/subscriberArea'

const subscriberareaPublic = ['subscriberAreaError', 'subscriberAreaLogin', 'subscriberAreaLoginIndex']

const subscriberAreaRouter: RouteConfig[] = [
    {
        path: '/subscriber-area',
        redirect: { name: 'subscriberAreaIndex' },
        name: 'subscriberArea',
        component: VsLayouts,
        beforeEnter: async (to: Route, _from: any, next: any) => {
            const queryToken = get(to, 'query.token', '')

            if (queryToken) {
                SubscriberAreaModule.SET_TOKEN(queryToken)
                SubscriberAreaModule.SET_USER(null)
                sessionStorage.setItem(SubscriberAreaModule.sessionStorageTokenName, queryToken)
            }

            if (!SubscriberAreaModule.token) {
                SubscriberAreaModule.SET_TOKEN(sessionStorage.getItem(SubscriberAreaModule.sessionStorageTokenName) || '')
            }

            if (!SubscriberAreaModule.user && !subscriberareaPublic.includes(to.name || '')) {
                try {
                    const resp = await getMe()
                    SubscriberAreaModule.SET_USER(resp.data)
                } catch (e) {
                    console.log(e)
                    SubscriberAreaModule.SET_TOKEN('')
                    sessionStorage.removeItem(SubscriberAreaModule.sessionStorageTokenName)
                    next({
                        name: 'subscriberAreaError',
                    })
                    return
                }
            }

            if (to?.query?.token) {
                next({
                    ...to,
                    query: {
                        ...to.query,
                        token: undefined,
                    },
                })
                return
            }

            next()
        },
        meta: {
            public: true,
        },
        children: [
            {
                path: 'lists',
                name: 'subscriberAreaIndex',
                component: () => import(/* webpackChunkName: "subscriber-area-index" */ './../views/index.vue'),
                meta: {
                    public: true,
                    layout: LayoutsEnum.vsFull,
                },
            },
            {
                path: 'lists/:listId',
                redirect: { name: 'subscriberAreaIndex' },
                meta: {
                    public: true,
                },
            },
            {
                path: 'lists/:listId/contacts',
                redirect: { name: 'subscriberAreaIndex' },
                meta: {
                    public: true,
                },
            },
            {
                path: 'lists/:listId/contacts/:contactId',
                name: 'subscriberAreaList',
                component: () => import(/* webpackChunkName: "subscriber-area-contact" */ './../views/contact.vue'),
                meta: {
                    public: true,
                    layout: LayoutsEnum.vsFull,
                },
            },
            {
                path: 'login',
                name: 'subscriberAreaLoginIndex',
                redirect: { name: 'subscriberAreaError' },
                meta: {
                    public: true,
                    layout: LayoutsEnum.vsFull,
                },
            },
            {
                path: 'login/:userId',
                name: 'subscriberAreaLogin',
                component: () => import(/* webpackChunkName: "subscriber-area-login" */ './../views/login.vue'),
                meta: {
                    public: true,
                    layout: LayoutsEnum.vsFull,
                },
            },
            {
                path: 'error',
                name: 'subscriberAreaError',
                component: () => import(/* webpackChunkName: "subscriber-area-error" */ './../views/error.vue'),
                meta: {
                    public: true,
                    layout: LayoutsEnum.vsFull,
                },
            },
        ],
    },
]

export default subscriberAreaRouter
