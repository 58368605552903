import { serviceFilemanager } from '@/utils/request'
import { AxiosRequestConfig } from 'axios'

const basePath = '/files'

export interface GetFilesQueryParams {
    mode?: string // onlyDeleted | withDeleted | notDeleted (default)
    search?: string
    searchFields?: string
    orderBy?: string
    sortedBy?: string
    searchJoin?: string
    page?: number
    limit?: number
    whereNull?: string | null
}

export const getFiles = (params?: GetFilesQueryParams) =>
    serviceFilemanager.get(basePath, {
        params,
    })

export const getTrashedFiles = (params?: GetFilesQueryParams) =>
    serviceFilemanager.get(`${basePath}/trash`, {
        params,
    })

export const getFileById = (id: string) =>
    serviceFilemanager({
        url: `${basePath}/${id}`,
        method: 'get',
    })

export const getFileAnchestors = (id: string) =>
    serviceFilemanager({
        url: `${basePath}/${id}/ancestors`,
        method: 'get',
    })

export interface UploadFileData {
    file: any[]
    directory_id?: string | null
}

export const uploadFile = (data: UploadFileData | any, options?: AxiosRequestConfig) =>
    serviceFilemanager.post(`${basePath}/upload`, data, {
        ...options,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        timeout: 30000,
    })

export interface ReplaceFileData {
    file: any
}

export const replaceFile = (id: string, data: ReplaceFileData | any) =>
    serviceFilemanager.post(`${basePath}/${id}/replace`, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        timeout: 30000,
    })

export interface UploadFileFromUrlData {
    urls: string[]
    directory_id?: string | null
}

export const uploadFileFromUrl = (data: UploadFileFromUrlData) =>
    serviceFilemanager.post(`${basePath}/upload`, data)

export interface RenameFileData {
    name: string
}

export const renameFile = (id: string, data: RenameFileData) =>
    serviceFilemanager.post(`${basePath}/${id}/rename`, data)

export interface MoveFileToDirectoryData {
    directory_id: string
}

export const moveFileToDirectory = (id: string, data: MoveFileToDirectoryData) =>
    serviceFilemanager.post(`${basePath}/${id}/move`, data)

export interface MoveFilesToDirectoryData {
    ids: string[]
    directory_id: string
}

export const moveFilesToDirectory = (data: MoveFilesToDirectoryData) =>
    serviceFilemanager.post(`${basePath}/move`, data)

export interface DuplicateFileData {
    name?: string
}

export const duplicateFile = (id: string, data: DuplicateFileData) =>
    serviceFilemanager.post(`${basePath}/${id}/duplicate`, data)

export interface RecoveryFilesData {
    ids: string[]
}

export const recoveryFiles = (data: RecoveryFilesData) =>
    serviceFilemanager.post(`${basePath}/recovery`, data)

export interface DownloadFileData {
    ids: string[]
}

export const downloadFiles = (data: DownloadFileData) =>
    serviceFilemanager({
        url: `${basePath}/download`,
        method: 'POST',
        responseType: 'blob',
        data,
    })

export interface DeleteFilesData {
    ids: string[]
}

export const deleteFiles = (data: DeleteFilesData) =>
    serviceFilemanager.delete(`${basePath}`, {
        data,
    })

export const moveToTrashFiles = (data: DeleteFilesData) =>
    serviceFilemanager.post(`${basePath}/trash`, data)

export const importFromUrl = (url: string) =>
    serviceFilemanager.post(`${basePath}/upload-from-url`, {
        urls: [url],
    })
