











































































import { Component, Vue } from 'vue-property-decorator'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsFullTopBarLayout from '@/components/VsFullTopBarLayout/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsActionCard from '@/components/VsActionCard/Index.vue'
import CreateFormModal from '@/modules/leadplus/components/CreateFormModal/Index.vue'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { createCampaign, createCampaignSplitTest } from '@/api/consoleApi/campaigns'
import { createEmail } from '@/api/consoleApi/contents/emails'
import { createSms } from '@/api/consoleApi/contents/sms'
// import { createSms } from '@/api/consoleApi/contents/sms'
import { UserModule } from '@/store/modules/user'
import { LeadplusForm } from '@/api/leadplus/types'

@Component({
    name: 'CreateCampaignModal',
    components: {
        VsContainer,
        VsFullTopBarLayout,
        CreateFormModal,
        VsLoader,
        VsActionCard,
    },
})
export default class extends Vue {
    private open = false
    private loading = false
    $refs: any

    get hasAbTest () {
        return UserModule.user?.configuration?.rules?.abTest || false
    }

    get campaignTypes () {
        return [
            {
                name: this.$t('campaigns.createCampaign.emailTitle'),
                description: this.$t('campaigns.createCampaign.emailDescription'),
                type: 'email',
                src: require('@/assets/img/campaigns/email-illustration.png'),
                disabled: false,
            },
            {
                name: this.$t('campaigns.createCampaign.smsTitle'),
                description: this.$t('campaigns.createCampaign.smsDescription'),
                type: 'sms',
                src: require('@/assets/img/campaigns/sms-illustration.png'),
                disabled: false,
            },
            {
                name: this.$t('campaigns.createCampaign.formTitle'),
                description: this.$t('campaigns.createCampaign.formDescription'),
                type: 'form',
                src: require('@/assets/img/campaigns/form-illustration.png'),
                disabled: false,
            },
            {
                name: this.$t('campaigns.createCampaign.pageTitle'),
                description: this.$t('campaigns.createCampaign.pageDescription'),
                type: 'page',
                src: require('@/assets/img/campaigns/page-illustration.png'),
                disabled: false,
            },
            {
                name: this.$t('campaigns.createCampaign.automationTitle'),
                description: this.$t('campaigns.createCampaign.automationDescription'),
                type: 'automation',
                src: require('@/assets/img/campaigns/robot-illustration.png'),
                disabled: false,
            },
            {
                name: this.$t('campaigns.createCampaign.abTitle'),
                description: this.$t('campaigns.createCampaign.abDescription'),
                type: 'ab',
                src: require('@/assets/img/campaigns/ab-illustration.png'),
                disabled: !this.hasAbTest,
            },
        ]
    }

    public openModal () {
        this.loading = false
        this.open = true
    }

    public closeModal () {
        this.open = false
    }

    private async createCampaign (type: string, disabled?: boolean) {
        if (disabled) {
            this.$root.$vsOpenBlockedModal()
            return
        }
        this.loading = true
        switch (type) {
            case 'email':
                await this.createCampaignEmail()
                break
            case 'sms':
                await this.createCampaignSms()
                break
            case 'form':
                this.$refs.createFormModal.openModal()
                this.loading = false
                break
            case 'page':
                await this.createCampaignPage()
                break
            case 'automation':
                await this.createCampaignAutomation()
                break
            case 'ab':
                await this.createCampaignAb()
        }
    }

    private async createCampaignAutomation () {
        window.location.href = `/app/user/automation/reset`
    }

    private async createCampaignPage () {
        if (!this.$router) {
            window.location.href = '/user/pages/create'
            return
        }
        this.$router.push({
            name: 'CreatePage',
        })
        this.loading = false
        this.closeModal()
    }

    private async redirectToForm (form: LeadplusForm) {
        if (!form) return

        if (!this.$router) {
            window.location.href = `/user/forms/${form._id}/edit`
            return
        }

        this.$router.push({
            name: 'editForm',
            params: {
                formId: form._id,
            },
        })

        this.loading = false
        this.closeModal()
    }

    private async createCampaignEmail () {
        try {
            const resp = await createEmail()
            const res = await createCampaign({
                name: 'Campagna senza nome',
                content_type: 'email',
                content_id: resp.data,
            })

            if (!this.$router) {
                window.location.href = `/user/campaigns/${res.data}/edit`
                return
            }

            // check for console
            if (this.$root.$vsToast) {
                this.$root.$vsToast({
                    heading: this.$t('campaigns.createCampaign.emailCreated'),
                    aspect: VsToastAspectEnum.success,
                    timeout: 6000,
                })
            }

            this.$router.push({
                name: 'campaignEdit',
                params: {
                    campaignId: res.data,
                },
            })
        } catch (e) {
            // check for console
            if (this.$root.$vsToast) {
                this.$root.$vsToast({
                    heading: this.$t('campaigns.createCampaign.emailCreatedError'),
                    aspect: VsToastAspectEnum.alert,
                    timeout: 6000,
                })
            }
        }
        this.loading = false
        this.closeModal()
    }

    private async createCampaignAb () {
        try {
            const res = await createCampaignSplitTest({
                name: 'Campagna senza nome',
            })

            if (!this.$router) {
                window.location.href = `/user/campaigns/${res.data}/edit`
                return
            }

            // check for console
            if (this.$root.$vsToast) {
                this.$root.$vsToast({
                    heading: this.$t('campaigns.createCampaign.emailCreated'),
                    aspect: VsToastAspectEnum.success,
                    timeout: 6000,
                })
            }

            this.$router.push({
                name: 'campaignEdit',
                params: {
                    campaignId: res.data,
                },
            })
        } catch (e) {
            // check for console
            if (this.$root.$vsToast) {
                this.$root.$vsToast({
                    heading: this.$t('campaigns.createCampaign.emailCreatedError'),
                    aspect: VsToastAspectEnum.alert,
                    timeout: 6000,
                })
            }
        }
        this.loading = false
        this.closeModal()
    }

    private async createCampaignSms () {
        try {
            const resp = await createSms()
            const res = await createCampaign({
                name: 'Campagna senza nome',
                content_type: 'sms',
                content_id: resp.data,
            })

            if (!this.$router) {
                window.location.href = `/user/campaigns/${res.data}/edit`
                return
            }

            // check for console
            if (this.$root.$vsToast) {
                this.$root.$vsToast({
                    heading: this.$t('campaigns.createCampaign.emailCreated'),
                    aspect: VsToastAspectEnum.success,
                    timeout: 6000,
                })
            }

            this.$router.push({
                name: 'campaignEdit',
                params: {
                    campaignId: res.data,
                },
            })
        } catch (e) {
            // check for console
            if (this.$root.$vsToast) {
                this.$root.$vsToast({
                    heading: this.$t('campaigns.createCampaign.emailCreatedError'),
                    aspect: VsToastAspectEnum.alert,
                    timeout: 6000,
                })
            }
        }
        this.loading = false
        this.closeModal()
    }
}
