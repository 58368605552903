import { VuexModule, Module, Mutation, getModule, Action } from 'vuex-module-decorators'
import store from '@/store'

export interface ISubscriberAreaState {
    sessionStorageTokenName: string
    token: string
    user: {
        credential: string
        credentialType: 'email' | 'mobile'
        userMongoId: string
    } | null
}

@Module({ dynamic: true, store, name: 'subscriberarea', namespaced: true })
class SubscriberArea extends VuexModule implements ISubscriberAreaState {
    public sessionStorageTokenName = 'fdsat'
    public token = ''
    public user: {
        credential: string
        credentialType: 'email' | 'mobile'
        userMongoId: string
    } | null = null

    @Mutation
    public SET_TOKEN (token: string) {
        this.token = token
    }

    @Mutation
    public SET_USER (user: {
        credential: string
        credentialType: 'email' | 'mobile'
        userMongoId: string
    } | null) {
        this.user = user
    }

    @Action({ rawError: true })
    public logout () {
        this.SET_TOKEN('')
        this.SET_USER(null)
        sessionStorage.removeItem(this.sessionStorageTokenName)
    }
}

export const SubscriberAreaModule = getModule(SubscriberArea)
