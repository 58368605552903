import { serviceConsoleApi } from '@/utils/request'
import { CancelToken } from 'axios'

export const getUserCredits = (cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/credits`,
        {
            cancelToken,
        },
    )

export const getReputation = (cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/user/reputation`,
        {
            cancelToken,
        },
    )

export const getDashboardDataBySection = (service: string, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/user/dashboard/${service}`,
        {
            cancelToken,
        },
    )

export const getLayoutSettings = (cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/user/layout`,
        {
            cancelToken,
        },
    )
