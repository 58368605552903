/* eslint-disable max-len */
const campaigns = {
    index: {
        title: 'Campagne',
        foundCampaigns: '{count} trovata | {count} trovate',
        orderByLabel: 'Ordina per',
        type: {
            placeholder: 'Tutte le tipologie',
            email: 'Email',
            sms: 'Sms',
            split_test: 'A/B test',
            noContent: 'Senza contenuto',
        },
        status: {
            placeholder: 'Tutti gli status',
            Draft: 'Bozze',
            Ready: 'Pianificate',
            Sending: 'In uscita',
            Paused: 'In pausa',
            Sent: 'Inviate',
            'Pending Approval': 'In approvazione',
            Failed: 'Fallite',
        },
        tagPlaceholder: 'Tutti i tag',
        searchPlaceholder: 'Cerca...',
        orderBy: {
            createdAtDesc: 'Più recenti',
            createdAtAsc: 'Meno recenti',
            sentAtAsc: 'Inviata meno recente',
            sentAtDesc: 'Inviata più recente',
        },
        emptyState: {
            title: 'Crea la tua prima campagna.',
            subtitle: 'Quando creerai una campagna email o SMS, comparirà qui. Pronto per iniziare?',
            actionText: 'Crea campagna',
        },
        createTagSuccess: 'Tag creato con successo',
        noResult: '0 campagne trovate',
        addTagSuccess: 'Tag assegnato con successo',
        removeTagSuccess: 'Tag rimosso con successo',
        deleteError: 'Errore durante la cancellazione della campagna | Errore durante la cancellazione delle campagne',
        deleteSuccess: '{count} campagna eliminata con successo | {count} campagne eliminate con successo',
    },
    actions: {
        newCampaign: 'Nuova campagna',
        checkCampaign: 'Controlla campagna',
        archive: 'Archivio newsletter',
        calendar: 'Calendario campagne',
        editCampaign: 'Modifica campagna',
        editCampaignName: 'Modifica nome campagna',
        scheduleCampaign: 'Pianifica e invia',
        createContent: 'Crea contenuto',
        editContent: 'Modifica contenuto',
        browserPreview: 'Visualizza anteprima',
        emailPreview: 'Anteprima via email',
        showStatistics: 'Guarda statistiche',
        clone: 'Duplica',
        saveAsModel: 'Salva come modello',
        exportHtml: 'Esporta html',
        exportSms: 'Esporta sms',
        createBooster: 'Crea booster',
        delete: 'Elimina',
    },
    topActions: {
        selectedFiles: '{count} campagna selezionata | {count} campagne selezionate',
        selectAll: 'Seleziona tutte',
        performance: 'Confronta performance',
        addTag: 'Assegna Tag',
        removeTag: 'Rimuovi Tag',
        delete: 'Elimina',
    },
    campaignCard: {
        sent: 'Inviate:',
        open: 'Aperture:',
        click: 'Click:',
        ctor: 'CTOR:',
        eventMessageUniversity: 'Niente panico, accedi a 4Dem University ti spieghiamo come mai è successo e come riprendere facilmente l\'invio.',
        more: 'Approfondisci',
        moreDetails: 'Vedi dettagli',
        ignore: 'Ignora e riprendi invio',
        status: {
            Draft: 'Bozza',
            Ready: 'Pianificata',
            Sending: 'In uscita',
            Paused: 'In pausa',
            Sent: 'Inviata',
            'Pending Approval': 'In approvazione',
            Failed: 'Fallita',
        },
        scheduleTypes: {
            immediate: 'Immediatamante',
            ricorsive: 'Ricorsiva',
        },
    },
    campaignExpandedCard: {
        sendFrom: 'Inviato da',
        sendTo: 'Inviato a',
        totalSent: 'Inviate',
        opened: 'Aperture',
        clicked: 'Click',
        ctor: 'CTOR',
    },
    events: {
        start: 'La campagna è in pausa',
        SenderNotVerified: 'perché il mittente non risulta autorizzato o confermato.',
        NotEnoughCredits: 'perché hai esaurito i crediti necessari per l\'invio.',
        EmailContentFetchError: 'perché il contenuto della url risulta irragiungibile.',
        CSADataNotValid: 'perché i campi obbligatori del mittente sono assenti sul mittente o nel contenuto dell\'e-mail',
        CampaignIsNotClean: 'perché abbiamo rilevato un eccesso negativo di indicatori di reputazione.',
        AbSplitWaitingForWinner: 'in attesa del risultato del test.',
        TooLowRecipientsForSendingAbTest: 'perchè i contatti non sono sufficienti per effettuare il test, puoi aggiungere contatti nella lista selezionata e riprendere l\'invio oppure terminare la campagna.',
    },
    confirmResumeCampaign: {
        title: 'Sei sicuro di voler far ripartire la campagna?',
        confirmButton: 'Sì, sono sicuro',
        closeButton: 'No, chiudi popup',
        clean: 'Questa campagna è stata messa in pausa perché abbiamo rilevato un eccesso negativo di indicatori di reputazione:',
        clean2: '• Disiscrizioni maggiori del: {count}% - la media globale è del 2,7%',
        clean3: '• Segnalazioni SPAM maggiori del {count}% - la media globale è del 0,05%',
        clean4: '• Bounce maggiori del {count}%  - la media è del 5%',
        clean5: 'Se rifai partire questa campagna è probabile che il tuo livello di reputazione si abbasserà influenzando la deliverability dei tuoi prossimi invii.',
        resumeError: 'Impossibile riprendere l\'invio',
        resumeErrorNotFound: ', campagna non trovata',
        resumeErrorNotPaused: ', campagna non più in pausa',
    },
    assignTagModal: {
        title: 'Assegna un tag',
        titleRemove: 'Rimuovi un tag',
        newTag: 'Crea nuovo tag',
        selectTag: 'Seleziona un tag',
        close: 'Annulla',
        save: 'Salva',
    },
    previewModal: {
        close: 'Chiudi',
        sender: 'Informazioni mittente:',
        subject: 'Oggetto:',
    },
    emailPreviewModal: {
        title: 'Invia un\'anteprima via email',
        subtitle: 'Seleziona massimo 5 contatti a cui inviare l\'anteprima.',
        subtitle2: 'Se non hai ancora nessun contatto, puoi aggiungerlo cliccando su "Aggiungi contatto ai preferiti".',
        subtitle3: 'Puoi gestire i tuoi contatti anche nella sezione',
        subtitleLink: 'Contatti preferiti',
        noAddressbooks: 'Non hai ancora contatti fra i tuoi preferiti',
        footerPlaceholder: 'Nessun footer',
        emailPlaceholder: 'Inserisci un indirizzo email',
        close: 'Chiudi',
        send: 'Invia',
        addEmail: 'Aggiungi email',
        yourAddressbook: 'I tuoi preferiti',
        addAddressbook: 'Aggiungi contatto ai preferiti',
        otherEmail: 'Invia l\'anteprima anche ad un indirizzo non presente tra i preferiti',
        emailSent: 'Email di anteprima inviata con successo',
        emailSentError: 'Errore durante l\'invio della email di anteprima',
        deleteAddressSuccess: 'Contatto preferito eliminato con successo',
        deleteAddressError: 'Errore durante l\'eliminazione del contatto preferito',
    },
    confirmDeleteCampaign: {
        confirmButton: 'Elimina definitivamente',
        title: 'Sicuro di voler rimuovere la campagna? | Sicuro di voler rimuovere le campagne?',
        subtitle: 'La campagna e le statistiche associate verranno eliminate e non sarà più possibile recuperarle. | Le campagne e le statistiche associate verranno eliminate e non sarà più possibile recuperarle.',
    },
    saveModelModal: {
        title: 'Crea modello',
        description: 'I modelli creati saranno disponibili nell\'area "I MIEI MODELLI" e potranno essere riutilizzati nelle prossime campagne.',
        titleLabel: 'Titolo',
        descriptionLabel: 'Descrizione',
        close: 'Chiudi',
        save: 'Crea',
        success: 'Il modello è stato creato con successo',
        error: 'Errore durante la creazione del modello',
    },
    createContentModal: {
        title: 'Che tipo di contenuto vuoi creare?',
        email: 'Email',
        sms: 'Sms',
    },
    heatmap: {
        title: 'Mappa di calore',
    },
    createCampaign: {
        title: 'Crea Campagna',
        emailTitle: 'Email',
        emailDescription: 'Crea una campagna con un contenuto email',
        smsTitle: 'Sms',
        smsDescription: 'Crea una campagna con un contenuto sms',
        formTitle: 'Form e popup',
        formDescription: 'Crea un form da integrare sul tuo sito',
        pageTitle: 'Landing Page',
        pageDescription: 'Crea una landing page con cui fare lead generation',
        automationTitle: 'Marketing automation',
        automationDescription: 'Crea dei flussi automatici per l\'invio di email e sms',
        abTitle: 'Email A/B test',
        abDescription: 'Crea e compara due contenuti differenti e scopri il più efficace',
        emailCreated: 'Campagna creata con successo e salvata in bozza',
        emailCreatedError: 'Errore durante la creazione della campagna',
    },
    editCampaign: {
        returnToCampaigns: 'Torna alle campagne',
        status: {
            Draft: 'Bozza',
            Ready: 'Pianificata',
            Sending: 'In uscita',
            Paused: 'In pausa',
            Sent: 'Inviata',
            'Pending Approval': 'In approvazione',
            Failed: 'Fallita',
        },
        actions: {
            finishLater: 'Finisci più tardi',
            schedule: 'Pianifica',
            editSchedule: 'Modifica pianificazione',
            pause: 'Metti in pausa',
            resumeCampaign: 'Riprendi campagna',
            showStatistics: 'Guarda statistiche',
            abort: 'Termina campagna',
            editCampaign: 'Modifica campagna',
            editCampaignName: 'Modifica nome campagna',
            removeSchedule: 'Annulla pianificazione',
            duplicateCampaign: 'Duplica campagna',
            preview: 'Anteprima',
            delete: 'Elimina',
        },
        campaignErrors: {
            notification: {
                heading: 'Informazioni mancanti',
                message: 'Compila tutte le sezioni mancanti:',
            },
        },
        hint: {
            badge: {
                warning: 'Puoi fare meglio',
                alert: 'Attenzione',
                error: 'Attenzione',
                success: 'Ottimo lavoro!',
            },
        },
        sections: {
            sender: {
                heading: 'Mittente',
                subtitle: 'A nome di chi vuoi inviare l\'email?',
                confirmed: 'Confermato',
                notConfirmed: 'Da confermare',
                replyTo: 'Rispondi a:',
                edit: 'Modifica',
                add: 'Aggiungi',
                addSender: 'Aggiungi un mittente',
                selectFrom: 'Seleziona tra quelli disponibili',
                senderCaption: 'Questo indirizzo è confermato',
                senderName: 'Nome mittente',
                senderNameCaption: 'Modifica il nome mittente con cui vuoi spedire questa email.',
                editReplyTo: 'Personalizza rispondi a',
                editReplyToCaption: 'Personalizza le informazioni di risposta alla tua email ovvero l\'indirizzo email e il nome a cui viene inviata la risposta nel caso in cui un contatto risponde a questa email. Se non specifichi nulla in automatico il sistema utilizza le informazioni del mittente selezionato.',
                emailReplyTo: 'Indirizzo email del rispondi a',
                nameReplyTo: 'Nome del rispondi a',
                or: 'oppure',
                createNewSender: 'Crea un nuovo mittente',
                createSender: 'Crea mittente',
                emptySender: 'Non hai ancora creato un mittente autorizzato',
                hint: {
                    heading: 'Il mittente è chi invia le tue email.',
                    confirmed: {
                        error: 'Questo indirizzo non è confermato, controlla la tua casella di posta e inserisci il codice di conferma.',
                        errorAction: 'Inserisci codice di conferma',
                        success: 'I tuoi contatti ti riconosceranno subito',
                        default: 'Utilizza un nome e un indirizzo email facilmente riconoscibili dai tuoi contatti',
                    },
                    dkim: {
                        default: 'Per aumentare la consegna delle tue email abilita il DKIM sul tuo mittente',
                    },
                    emoji: {
                        default: 'Non aggiungere emoji nel nome mittente o nel nome del rispondi a perché aumenta il rischio di essere considerati spam.',
                    },
                },
                errors: {
                    disabled: 'Questo indirizzo non è confermato, controlla la tua casella di posta e inserisci il codice di conferma.',
                    saveError: 'Errore durante il salvataggio del mittente',
                    notVerify: 'Mittente non ancora verificato',
                    controllInbox: 'controlla la tua casella di posta per inserire il codice di conferma',
                },
                saveSuccess: 'Mittente salvato con successo',
            },
            senderSms: {
                heading: 'Mittente',
                subtitle: 'A nome di chi vuoi inviare il tuo sms?',
                confirmed: 'Confermato',
                notConfirmed: 'Da confermare',
                edit: 'Modifica',
                add: 'Aggiungi',
                addSender: 'Aggiungi un mittente',
                selectFrom: 'Seleziona tra quelli disponibili',
                senderCaption: 'Questo mittente è confermato',
                senderName: 'Nome mittente',
                senderNameCaption: 'Modifica il nome mittente con cui vuoi spedire questo sms.',
                or: 'oppure',
                createNewSender: 'Crea un nuovo mittente',
                createSender: 'Crea mittente',
                emptySender: 'Non hai ancora creato un mittente autorizzato',
                hint: {
                    heading: 'Il mittente è chi invia i tuoi sms.',
                    confirmed: {
                        error: 'Questo mittente non è confermato, controlla fra i tuoi sms e inserisci il codice di conferma.',
                        errorAction: 'Inserisci codice di conferma',
                        success: 'Il tuo mittente è confermato e i tuoi contatti ti riconosceranno subito',
                        default: 'Utilizza un nome mittente facilmente riconoscibile dai tuoi contatti',
                    },
                    enabled: {
                        default: 'Il mittente deve essere verificato da AGCOM',
                        success: 'Questo mittente sms è stato approvato dall\'AGCOM',
                        approval: 'Questo mittente sms non è ancora stato approvato dall\' AGCOM',
                        disabled: 'Questo mittente sms non è valido per il nostro provider di invio',
                    },
                },
                errors: {
                    disabled: 'Questo mittente non è confermato, controlla fra i tuoi sms e inserisci il codice di conferma.',
                    saveError: 'Errore durante il salvataggio del mittente',
                    notVerify: 'Mittente non ancora verificato',
                    controllInbox: 'controlla fra i tuoi sms per inserire il codice di conferma',
                },
                saveSuccess: 'Mittente salvato con successo',
            },
            singleTarget: {
                heading: 'Destinatario',
            },
            target: {
                heading: 'Destinatari',
                subtitle: 'A chi vuoi inviare il messaggio?',
                estimateTip: 'La stima non tiene conto dei contatti duplicati',
                estimateTip2: 'Attenzione, questo messaggio potrebbe essere inviato a nessun contatto',
                estimateContacts: '{count} contatto stimato | {count} contatti stimati',
                filters: '{count} filtro | {count} filtri',
                exclusion: 'eccetto ',
                edit: 'Modifica',
                editTarget: 'Filtra il tuo target',
                listLabel: 'Lista',
                listPlaceholder: 'Seleziona una lista',
                profilationLabel: 'Profilazioni',
                profilationTip: 'Tutte le profilazioni non sempre corrispondono a tutti i destinatari della tua lista. Lascia vuoto questo campo per inviare a tutta la lista.',
                profilationPlaceholder: 'Nessuna (Tutti i contatti)',
                allProfilations: 'Tutte le profilazioni',
                addList: 'Aggiungi una lista',
                or: 'oppure',
                excludeLists: 'Escludi una o più liste',
                exeptContact: 'eccetto i contatti:',
                exclusionListPlaceholder: 'Seleziona una lista per escludere i contatti',
                addExclusionList: 'Aggiungi una lista',
                selectedListsSubtitle: ' | {count} lista | {count} liste',
                selectedProfilationsSubtitle: ' | {count} filtro | {count} filtri',
                listNotFound: 'Lista non trovata',
                profilationNotFound: 'Lista non trovata',
                allList: 'Tutta la lista',
                shared: '(Condivisa)',
                listsSaved: 'Destinatari salvati con successo',
                boosterCampaignNotification: 'Non è possibile modificare la lista di destinatari di una campagna Booster',
                errors: {
                    unique: 'Le liste e i filtri di esclusione non possono essere uguali a quelle dei destinatari',
                    invalidRecipientOrSegment: 'non è una lista o un filtro a cui puoi inviare',
                    default: 'Errore durante il salvataggio dei destinatari',
                },
            },
            subject: {
                heading: 'Oggetto',
                subtitle: 'Qual è l\'oggetto della tua email?',
                edit: 'Modifica',
                add: 'Aggiungi',
                editSubject: 'Aggiungi un oggetto',
                subjectLabel: 'Oggetto del messaggio (caratteri {count}/100)',
                emoji: {
                    search: 'Ricerca',
                    notfound: 'No Emoji Found',
                    categories: {
                        search: 'Risultato della ricerca',
                        recent: 'Recenti',
                        people: 'Smileys & Persone',
                        nature: 'Animali & Natura',
                        foods: 'Cibo & Bevande',
                        activity: 'Attività',
                        places: 'Viaggi & Posti',
                        objects: 'Oggetti',
                        symbols: 'Simboli',
                        flags: 'Bandiere',
                        custom: 'Custom',
                        clear: 'Pulisci',
                    },
                },
                insertCustomField: 'Inserisci campo personalizzato',
                summaryLabel: 'Sommario (caratteri {count}/100)',
                summaryCaption: 'Un contenuto conciso che invogli l\'apertura dell\'email (opzionale)',
                hint: {
                    heading: 'L\'oggetto è uno dei principali elementi che determina l\'apertura di un\'email',
                    maxWord: {
                        warning: 'Utilizza massimo 9 parole',
                        success: 'Un oggetto corto massimizza l\'efficacia',
                    },
                    maxCharacter: {
                        default: 'Non superare i 60 caratteri',
                    },
                    maxExclamationMark: {
                        default: 'Fai attenzione all\'abuso di punti esclamativi',
                    },
                    summaryHeading: 'Il sommario è la riga di testo che appare subito dopo l\'oggetto.',
                    differentFromSubject: {
                        default: 'Cerca di dare un\'anteprima del contenuto senza ripetere quello che c\'è scritto nell\'oggetto',
                        warning: 'Utilizza un sommario diverso dall\'oggetto',
                        success: 'Il sommario è diverso dall\'oggetto',
                    },
                },
                subjectSave: 'Oggetto salvato con successo',
                subjectSaveError: 'Errore durante il salvataggio dell\'oggetto',
            },
            content: {
                heading: 'Contenuto',
                subtitle: 'Progetta la tua email',
                tagBadgeTip: '{open} tag all\'apertura <br>{link} tag sui link',
                tagBadge: '{count} tag aggiunto | {count} tag aggiunti',
                labelBadge: '{count} etichetta aggiunta | {count} etichette aggiunte',
                labelOpenBadgeTip: '{count} etichetta all\'apertura <br> | {count} etichette all\'apertura <br>',
                labelClickBadgeTip: '{count} etichetta al click | {count} etichette al click',
                manageLabel: 'Gestisci profilazione contatti',
                edit: 'Modifica',
                editContent: 'Modifica contenuto',
                showPreview: 'Visualizza anteprima',
                spamTest: 'Effettua lo spam test',
                deleteContent: 'Elimina contenuto',
                hint: {
                    heading: 'Consigli sul tuo contenuto',
                    onlyTextContent: {
                        error: 'Imposta un contenuto solo testo per garantire una completa lettura su tutti i client di posta',
                        errorUnsubscribeLink: 'Inserisci nel contenuto solo testo il link di disiscrizione',
                        errorConfirmationLink: 'Inserisci nel contenuto solo testo il link di conferma iscrizione',
                        errorCsaData: 'Inserisci nel contenuto solo testo i dati del mittente senza modificare i placeholder es. %Csa:Nominative%.',
                        errorAction: 'Aggiungi',
                        success: 'Imposta un contenuto solo testo per garantire una completa lettura su tutti i client di posta',
                        successAction: 'Modifica',
                    },
                    unsubscribeLink: {
                        default: 'Aggiungi il link di disiscrizione',
                        defaultAction: 'Modifica contenuto',
                    },
                    csa: {
                        default: 'Aggiungi nel corpo della email l\'elemento singolo "dati del mittente" che trovi nel nostro editor senza modificare i placeholder es. %Csa:Nominative%.',
                        defaultAction: 'Modifica contenuto',
                    },
                    placeholderImg: {
                        default: 'Non usare immagini vuote',
                        defaultAction: 'Modifica contenuto',
                    },
                },
                tagHeading: 'Profilazione con tag comportamentali',
                tagEmpty: 'Vuoi tracciare il comportamento con dei tag?',
                manageTag: 'Gestisci profilazione contatti',
                deleteContentConfirm: {
                    heading: 'Elimina contenuto',
                    content: 'Procedendo, perderai tutti i contenuti creati.',
                    submit: 'Procedi ed elimina contenuti',
                },
                editorTypes: {
                    dragoTitle: 'Da editor drag&drop',
                    dragoSubtitle: 'Crea email in un lampo con Drago, il nostro editor visuale',
                    texteditorTitle: 'Da zero con l\'editor di testo',
                    texteditorSubtitle: 'Scrivi il contenuto con un editor semplice stile Word',
                    importTitle: 'Importa HTML',
                    importSubtitle: 'Carica uno .zip oppure recupera il codice da URL',
                },
                texteditor: 'Creato con editor di testo',
                drago: 'Creato con editor drag&drop',
                contentSaved: 'Contenuto salvato con successo',
                contentSavedError: 'Errore durante il salvataggio del contenuto',
                onlyTextSaved: 'Contenuto solo testo salvato con successo',
                onlyTextSavedError: 'Errore durante il salvataggio del contenuto solo testo',
            },
            contentSms: {
                heading: 'Contenuto',
                subtitle: 'Definisci il contenuto sms',
                charactersCount: '{count} carattere | {count} caratteri',
                messagesCount: '{count} messaggio | {count} messaggi',
                edit: 'Modifica',
                editContent: 'Modifica contenuto',
                showPreview: 'Visualizza anteprima',
                spamTest: 'Effettua lo spam test',
                deleteContent: 'Elimina contenuto',
                countTip: 'Il conteggio potrebbe risultare approssimato se sono presenti dei contenuti variabili come i campi personalizzati.',
                textareaLabel: 'Caratteri:',
                textareaCaption: 'Messaggi:',
                insertShortUrl: 'Inserisci url breve',
                hint: {
                    heading: 'Consigli sul tuo contenuto',
                    smsLengthStatus: {
                        default: 'Il numero massimo di caratteri ammesso è 640.',
                    },
                    hasBody: {
                        default: 'Inserisci un testo per contare i messaggi.',
                        success: 'Il tuo contenuto contiene',
                    },
                },
                tagHeading: 'Profilazione con tag comportamentali',
                tagEmpty: 'Vuoi tracciare il comportamento con dei tag?',
                manageTag: 'Gestisci profilazione contatti',
                deleteContentConfirm: {
                    heading: 'Elimina contenuto',
                    content: 'Procedendo, perderai tutti i contenuti creati.',
                    submit: 'Procedi ed elimina contenuti',
                },
                editorTypes: {
                    dragoTitle: 'Da editor drag&drop',
                    dragoSubtitle: 'Crea email in un lampo con Drago, il nostro editor visuale',
                    texteditorTitle: 'Da zero con l\'editor di testo',
                    texteditorSubtitle: 'Scrivi il contenuto con un editor semplice stile Word',
                    importTitle: 'Importa HTML',
                    importSubtitle: 'Carica uno .zip oppure recupera il codice da URL',
                },
                texteditor: 'Creato con editor di testo',
                drago: 'Creato con editor drag&drop',
                contentSaved: 'Contenuto salvato con successo',
                contentSavedError: 'Errore durante il salvataggio del contenuto',
                onlyTextSaved: 'Contenuto solo testo salvato con successo',
                onlyTextSavedError: 'Errore durante il salvataggio del contenuto solo testo',
            },
            advanced: {
                heading: 'Impostazioni avanzate',
                subtitle: 'Abilita una o più funzionalità tra quelle presenti',
                emptyBadge: 'Nessuna',
                analytics: 'Google Analytics',
                goal: 'Goal Tracking',
                ecommerce: 'Ecommerce Tracking',
                attachments: '{count} allegato | {count} allegati',
                edit: 'Modifica',
                activeAnalytics: 'Attiva Google Analytics Tracking',
                analyticsCaption: 'Usa questa opzione se vuoi aggiungere su tutti i link di questa campagna i parametri di tracciamento utm per Google Analytics.',
                analyticsUtmSource: 'UTM Source',
                analyticsUtmMedium: 'UTM Medium',
                analyticsUtmCampaign: 'UTM Campaign',
                activeGoal: 'Attiva Goal Tracking',
                goalCaption: 'Aggiunge ai link i parametri di Goal Tracking che puoi sfruttare per creare flussi di Automation Plus',
                trackCode: 'Codice di tracciamento',
                codeDesc: 'Inserisci questo codice nell\'header del tuo sito o della landing page che vuoi monitorare per attivare il Goal Tracking.',
                activeEcommerce: 'Attiva Ecommerce Tracking',
                captionEcommerce: 'Funzionalità disponibile solo per utenti con E-commerce Plus ATTIVO. La funzionalità consente di tracciare l\'iscritto al quale stai inviando questa comunicazione fino al suo eventuale acquisto sul sito. Così puoi misurare in tempo reale l\'efficacia delle tue email promozionali.',
                cookieDuration: 'Tempo di vita del cookie',
                cookieCaption: 'Inserisci il numero di giorni in cui il cookie sarà attivo. Se ad esempio ti aspetti che la tua email promozionale produrrà i suoi effetti in termini di Acquisti sul sito entro sette giorni dalla prima interazione dell\'iscritto con la mail (clic), imposta il numero 7. In questo modo tutti gli utenti che cliccheranno sui link saranno tracciati per i successivi sette giorni e se concluderanno un acquisto in questo arco di tempo, l\'acquisto sarà ricondotto a questa email.',
                attachmentsHeading: 'Allegati',
                noAttachments: 'Nessun allegato',
                deleteAttachments: 'Rimuovi allegato',
                attachmentSpaceAvailable: '{size} di {totalsize} disponibili',
                addAttachments: 'Aggiungi allegato',
                advancedSaved: 'Avanzate salvate con successo',
                advancedSavedError: 'Errore durante il salvataggio delle avanzate',
                advancedSavedAnalyticsError: 'Per attivare analytics devi collegare almeno un dominio',
                advancedSavedInvalidDomainError: 'Il dominio deve essere un dominio valido',
                codeCopied: 'Codice copiato con successo',
                codeCopiedError: 'Codice non copiato',
                attachmentSaved: 'Allegato aggiunto con successo',
                attachmentSavedError: 'Errore durante il caricamento dell\'allegato',
                attachmentSavedLimitError: 'Hai superato la dimensione massima di allegati caricabili',
                attachmentSavedSizeError: 'Il file inserito è troppo grande',
                attachmentDeleted: 'Allegato eliminato con successo',
                attachmentDeletedError: 'Errore durante l\'eliminazione dell\'allegato',
            },
        },
        scheduleCampaignModal: {
            heading: 'Pianifica invio',
            scheduleNow: 'Invia adesso',
            scheduleAtTime: 'Pianifica per una data e ora specifica',
            datePickerCaption: 'La campagna verrà inviata in questa data',
            schedule: 'Pianifica',
        },
        confirmDraft: {
            heading: 'Modifica campagna',
            message: 'Per modificare la campagna devi rimetterla in bozza e annullare le impostazioni di pianificazione.',
            confirmButton: 'Rimuovi pianificazione e modifica',
        },
        confirmDraftAbort: {
            heading: 'Sicuro di voler rimuovere la pianificazione?',
            message: 'Rimuovendo la pianificazione la campagna verrà rimessa in bozza.',
            confirmButton: 'Rimuovi pianificazione',
        },
        confirmDeleteCampaign: {
            heading: 'Sicuro di voler rimuovere la campagna?',
            message: 'La campagna e le statistiche associate verranno eliminate e non sarà più possibile recuperarle.',
            confirmButton: 'Elimina campagna',
        },
        confirmAbort: {
            heading: 'Sicuro di voler terminare la campagna?',
            message: 'Terminando la campagna non sarà più possibile riprendere l\'invio ma rimarranno i dati e le statistiche collegate.',
            confirmButton: 'Termina ora',
        },
        confirmPause: {
            heading: 'Sicuro di voler mettere in pausa la campagna?',
            message: 'Se alcuni utenti hanno già ricevuto le tue comunicazioni, non le riceveranno di nuovo una volta fatta ripartire.',
            confirmButton: 'Metti in pausa',
        },
        forceResumeConfirm: {
            heading: 'Sicuro di voler forzare l\'invio?',
            message: 'Ricorda che se la campagna è stata messa in pausa per un eccesso negativo di indicatori di reputazione, riprendere l\'invio potrebbe compromettere definitivamente la tua reputazione.',
            support: 'Contatta il centro di supporto per un\'analisi approfondita.',
            confirmButton: 'Riprendi invio',
        },
        toasts: {
            notSchedulable: 'Non è possibile pianificare',
            campaignScheduleSuccess: 'Campagna schedulata con successo',
            campaignScheduleError: 'Errore durante la schedulazione della campagna',
            campaignScheduleErrorInsufficientCredit: 'Crediti non sufficienti!',
            campaignScheduleErrorInsufficientCreditMessage: 'Non hai abbastanza crediti per inviare la campagna.',
            UnsubscriptionLinkMissingInPlainContent: 'Il contenuto solo testo non contiene il link di disiscrizione.',
            CsaDataMissingInPlainContent: 'Il contenuto solo testo non contiene i dati CSA.',
            SenderMustHaveCsaData: 'Il mittente selezionato non contiene i dati CSA.',
            SenderDkimNotValid: 'La configurazione DKIM del mittente selezionato non è valida.',
            pauseCampaignSuccess: 'Campagna messa in pausa',
            pauseCampaignError: 'Non è possibile mettere la campagna in pausa',
            abortCampaignSuccess: 'Campagna terminata',
            abortCampaignError: 'Non è possibile terminare la campagna',
            draftCampaignSuccess: 'Campagna messa in bozza',
            draftCampaignError: 'Non è possibile mettere la Campagna in bozza',
            closeBeforeSchedule: 'Salva le modifiche o chiudi le sezioni prima di pianificare',
            resumeCampaignSuccess: 'Campagna in corso',
            resumeCampaignError: 'Non è possibile riprendere la campagna',
            updateNameCampaignSuccess: 'Nome della campagna salvato con successo',
            generalUpdateCampaignSuccess: 'Campagna salvata con successo',
            generalUpdateCampaignError: 'Errore durante il salvataggio della campagna',
            maxLengthCampaignNameError: 'Il nome della campagna non può essere più lungo di 250 caratteri',
            deleteCampaignSuccess: 'Campagna eliminata con successo',
            deleteCampaignError: 'Errore durante la cancellazione della campagna',
            duplicateCampaignSuccess: 'Campagna duplicata con successo',
            duplicateCampaignError: 'Errore durante la duplicazione della campagna',
        },
        campaignStatusCard: {
            forceSend: 'Forza l\'invio',
            refresh: 'Aggiorna',
            campaignScheduledAt: 'Invio pianificato per il',
            campaignSending: 'Invio in corso',
            campaignSent: 'Inviata a {count} contatto | Inviata a {count} contatti',
            campaignSentAt: 'inviata a solo {count} contatto | inviata a solo {count} contatti',
            campaignSentExpected: 'di {count} previsto | di {count} previsti',
            campaignPendingApproval: 'Questa campagna è in corso di revisione',
            campaignFailed: 'Campagna interrotta',
            campaignStartAt: 'Campagna interrotta',
            at: 'alle',
            campaignStartedAt: 'L\'invio è iniziato il {hours} alle {minutes}',
            campaignDuration: ' ed è durato',
        },
    },
    createSmsSender: {
        data: {
            alias: 'Mittente',
            agcom: 'NUMERICO comprensivo di prefisso internazionale (esempio: +391234567890); ALFANUMERICO fino a 11 caratteri lettere e/o numeri. I mittenti saranno registrati secondo {action} AGCOM.',
            agcomAction: 'requisiti e codici di condotta',
            company: 'Ragione sociale',
            vat: 'Partita IVA',
            fiscalCode: 'Codice Fiscale',
            address: 'Indirizzo',
            city: 'Città',
            province: 'Sigla Provincia',
            country: 'Nazione',
            cap: 'CAP',
            phoneNumber: 'Telefono Cellulare',
            email: 'Indirizzo email',
            pec: 'Indirizzo email PEC',
            website: 'Sito web',
            approveText: 'L\'approvazione avviene manualmente e può richiedere da pochi minuti fino a 24 ore di tempo.',
        },
        tips: {
            company: 'Inserisci il nome della tua azienda.',
            address: 'Inserisci l\'indirizzo della tua azienda',
            vat: 'Inserisci la Partita IVA senza il prefisso nazionale (es. 12345678912)',
            phoneNumber: 'Riceverai su questo numero un sms con un codice di conferma che dovrai inserire per utilizzare il mittente autorizzato.',
            fiscalCode: 'Inserisci il codice fiscale della persona di riferimento della tua azienda.',
        },
        senderCreated: 'Mittente creato con successo. Ti abbiamo inviato un sms con il codice di conferma ed è stato inviato per l\'approvazione.',
        senderConfirmationEmailSent: 'Messaggio di confema inviato con successo al numero di cellulare specificato. Controlla fra i messagi e copia il codice',
        errors: {
            userNotFoundOrNotAuthorized: 'Errore generico, contatta il centro di supporto.',
            dataForSendNotValid: 'Errore generico, contatta il centro di supporto.',
            routeNotValid: 'Errore generico, contatta il centro di supporto.',
            parametersNotValid: 'Errore generico, contatta il centro di supporto.',
            userTypeNotValid: 'Errore generico, contatta il centro di supporto.',
            routeNotEnabled: 'Errore generico, contatta il centro di supporto.',
            creditNotSufficientForSendInSpecifiedRoute: 'Errore generico, contatta il centro di supporto.',
            senderNotValid: 'Il mittente non è valido.',
            smsBodyNotValid: 'Il corpo del messaggio non è valido.',
            creditInsufficient: 'Crediti insufficienti.',
            smsBodyContainerInvalidCharacters: 'Il corpo del messaggio contiene caratteri non validi.',
            timestampNotValid: 'Errore generico, contatta il centro di supporto.',
            timestampNotAllowed: 'Errore generico, contatta il centro di supporto.',
            dataSentNotRegistered: 'Errore generico, contatta il centro di supporto.',
            impossibileDeductCreditsForSend: 'Errore generico, contatta il centro di supporto.',
            confirmationNotReached: 'Errore generico, contatta il centro di supporto.',
            errorDuringSentSms: 'Errore durante l\'invio del messaggio col codice di conferma, contatta il centro di supporto.',
            errorDuringSentConfirmationCode: 'Errore durante l\'invio del codice di conferma.',
            creditNotSufficientToSendConfirmationSms: 'Non hai crediti a sufficienza per inviare sms di conferma del mittente. Fino a quando non confermi il numero di cellulare non potrai utilizzare il mittente.',
            invalidAlias: 'Il campo @:campaigns.createSmsSender.data.alias deve essere alfanumerico da 2 a 11 caratteri',
            invalidBusinessName: 'Il campo @:campaigns.createSmsSender.data.company non è valido',
            invalidVatNumber: 'Il campo @:campaigns.createSmsSender.data.vat non è valido',
            invalidAddress: 'Il campo @:campaigns.createSmsSender.data.address non è valido',
            invalidCity: 'Il campo @:campaigns.createSmsSender.data.city non è valido',
            invalidPostCode: 'Il campo @:campaigns.createSmsSender.data.cap non è valido',
            invalidProvince: 'Il campo @:campaigns.createSmsSender.data.province non è valido',
            invalidCountry: 'Il campo @:campaigns.createSmsSender.data.country non è valido',
            invalidEmail: 'Il campo @:campaigns.createSmsSender.data.email non è valido',
            invalidPhone: 'Il campo @:campaigns.createSmsSender.data.phoneNumber non è valido',
            invalidTaxCode: 'Il campo @:campaigns.createSmsSender.data.fiscalCode non è valido',
            invalidPec: 'Il campo @:campaigns.createSmsSender.data.pec non è valido',
        },
    },
    createCampaignBooster: {
        title: 'Campagna Booster',
        subtitle: 'Scegli a chi vuoi inviare la tua campagna',
        notOpened: 'Chi non ha aperto',
        notOpenedSubtitle: 'La campagna booster verrà inviata a tutti i contatti che non hanno aperto la campagna.',
        opened: 'Chi ha aperto',
        openedSubtitle: 'La campagna booster verrà inviata a tutti i contatti che hanno aperto la campagna.',
        clicked: 'Chi ha cliccato',
        clickedSubtitle: 'La campagna booster verrà inviata a tutti i contatti che hanno cliccato uno o tutti i link della campagna.',
        newContacts: 'Ai nuovi iscritti',
        newContactsSubtitle: 'La campagna booster verrà inviata a tutti i nuovi contatti che si sono iscritti alla lista a cui hai inviato la campagna.',
        boosterCreated: 'Campagna booster creata con successo',
        boosterError: 'Errore durante la creazione della campagna booster',
        newContactsModalTitle: 'Ai nuovi iscritti',
        newContactsSelectLabel: 'Seleziona una data',
        newContactsDateCaption: 'La campagna verrà inviata a tutti i contatti iscritti dopo la data selezionata',
        boosterModalCloseAction: 'Chiudi',
        boosterModalSaveAction: 'Crea',
        clickedModalTitle: 'Chi ha cliccato',
        clickedSelectCaption: 'La campagna verrà inviata a tutti i contatti che hanno cliccato il link selezionato',
        clickedSelectLabel: 'Seleziona un link',
        allLink: 'Tutti i link',
    },
    statistics: {
        average: 'Nella media',
        aboveAverage: 'Superiore alla media',
        thresholdParam: {
            under: 'La tua percentuale di {userPercentage}% è inferiore al valore considerato negativo di {thresholdPercentage}%',
            over: 'La tua percentuale di {userPercentage}% è superiore al valore considerato negativo di {thresholdPercentage}%',
        },
        bounce: {
            type: {
                hard_bounce: 'Hard',
                soft_bounce: 'Soft',
            },
        },
    },
}
export default {
    campaigns,
}
